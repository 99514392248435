@import "./styles.scss";

:root {
    // Generic variables
    --theme-primary: #{$bittersweet};
    --theme-secondary: #fff;
    --theme-background: #1b1918;
    --theme-border-color: #{$eclipse};
    --theme-border-color-secondary: #1b1918;
    --theme-font-family: 'Roboto', sans-serif;
    --theme-secondary-font-family: 'Roboto', sans-serif;
    --theme-font-weight: 700;
    --theme-font-weight-secondary: 500;
    --theme-text-transform: uppercase;
    --theme-text-primary: #161616;
    --theme-text-secondary: #5A5A5A;
    --theme-text-disabled: #{$red-disabled};
    --theme-text-secondary-disabled: #979797;
    --theme-header-font-size: 18px;
    --theme-text-tertiary: #{$light-blue};
    // Context menu
    --context-menu-background: #{$mb-black};
    --context-menu-text-color: #FFFFFF;
    --context-menu-hover-background: #{$moderate-blue};
    --context-menu-hover-background-disabled: #{$eclipse};
    --context-menu-divider-color: #{$eclipse};
    --context-menu-cursor-disabled: pointer;
    // Input
    --input-background-color: #FFFFFF;
    --input-border-color: #1B1B1B;
    --input-boder-radius: 2px;
    --input-with-border-border-color: #{$moderate-blue};
    // Checkbox
    --input-checkbox-border-radius: 0px;
    --input-checkbox-checked-background-color: transparent;
    --input-checkbox-checked-color: #{$black};
    --input-checkbox-size: 20px;
    // Radio
    --input-radio-size: 20px;
    --input-radio-outer-color: #{$mb-black};
    --input-radio-inner-color: #fff;
    --input-radio-border: 1px solid  #{$silver};
    --input-radio-inner-size: 8px;
    --input-radio-inner-position: 6px;
    // Toggler
    --input-toggler-toggled-color: #{$bittersweet};
    --input-toggler-untoggled-color: #{$white};
    --input-toggler-undoggled-circle-color: #{$davy-gray};
    --input-toggler-width: 50px;
    --input-toggler-height: 22px;
    --input-toggler-radius: 10px;
    --input-toggler-position: 2px;
    // Header
    --header-primary-text-color: #fff;
    --header-secondary-text-color: #{$ford-gray};
    --header-background-color: #{$eclipse};
    --header-highlight-color: #{$mb-black};

    --dropdown-background: #{$mb-black};
    --dropdown-primary-text-color: #{$white};
    --dropdown-secondary-text-color: #{$silver};
    --dropdown-selected-color: #{$white};
    --dropdown-border: #{$eclipse};
    --dropdown-border-selected: #{$eclipse};
    --dropdown-option-hover: #{$mb-black};
    --dropdown-option-selected: #{$eclipse};
    --dropdown-option-invalid: #{$eclipse};
    --dropdown-option-warning-hover: #{$mb-black};
    --dropdown-option-text-color: #{$silver};
    --dropdown-option-text-color-disabled: #{$davy-gray};
    --dropdown-option-text-color-warning: #{$yellow};
    --dropdown-option-border-hover: #{$davy-gray};
    --dropdown-option-border-selected-hover: #{$eclipse};
    --dropdown-option-border-warning-hover: #{$yellow};
    --dropdown-divider: #{$mb-black};
    --dropdown-splitter: #{$davy-gray};
    --dropdown-option-border-radius: 8px;
    --dropdown-footer: #{$eclipse};

    --dropdown-printmode-font-size: 18px;
    --dropdown-printmode-font-weight: 400;
    --dropdown-printmode-selected: #{$root-blue};
    --dropdown-printmode-hover: #{$white};
    --dropdown-printmode-selected-outline: #{$moderate-blue};
    --dropdown-printmode-text: #{$davy-gray};
    --dropdown-printmode-text-hover: #{$davy-gray};
    --dropdown-printmode-text-primary: #{$bittersweet};
    --dropdown-printmode-text-disabled: #{$red-disabled};
    --dropdown-printmode-text-transform: uppercase;

    --scene-tree-parent-selected: #{$root-blue};
    --scene-tree-item-selected: #{$smoke-blue};
    --scene-tree-item-icon-color: #161616;
    --scene-tree-item-checkbox: #{$silver};
    --scene-tree-dropdown-hover: #{$smoke-blue};

    --theme-input-bg: #FFFFFF;
    --theme-input-border-color: #1B1B1B;
    --theme-input-border-radius: 2px;
    --theme-button-hover-background: #{$very-lite-gray};
    --theme-button-hover-fill: #{$bittersweet};
    --theme-component-background-white: #FFFFFF;
    --theme-button-font-size: 14px;
    --theme-toaster-accent: #FFFFFF;
    --theme-switch-inactive-border: #{$silver};

    --theme-sidebar-shadow: 0px 8px 24px rgba(128, 128, 128, 0.1);

};

:global(.theming--light) {
    // Generic variables
    --theme-primary: #100AED;
    --theme-secondary: #2f2b2b;
    --theme-background: #1B1918;
    --theme-border-color: #EAEAEA;
    --theme-border-color-secondary: #F0ECE6;
    --theme-font-family: 'Messina Sans', sans-serif;
    --theme-secondary-font-family: 'IBM Plex Mono', monospace;
    --theme-font-weight: 500;
    --theme-font-weight-secondary: 500;
    --theme-text-transform: capitalize;
    --theme-text-primary: #161616;
    --theme-text-secondary: #5A5A5A;
    --theme-text-disabled: #706CF4;
    --theme-text-secondary-disabled: #979797;
    --theme-header-font-size: 14px;
    --theme-text-tertiary: #100AED;
    // Context menu
    --context-menu-background: #FFFFFF;
    --context-menu-text-color: #161616;
    --context-menu-hover-background: #F1F1F1;
    --context-menu-hover-background-disabled: transparent;
    --context-menu-divider-color: #F1F1F1;
    --context-menu-cursor-disabled: default;
    // Input
    --input-background-color: #F1F1F1;
    --input-border-color: transparent;
    --input-boder-radius: 4px;
    --input-with-border-border-color: #100AED;
    // Checkbox
    --input-checkbox-border-radius: 2px;
    --input-checkbox-checked-background-color: #000;
    --input-checkbox-checked-color: #fff;
    --input-checkbox-size: 16px;
    // Radio
    --input-radio-size: 12px;
    --input-radio-outer-color: transparent;
    --input-radio-inner-color: #000;
    --input-radio-border: 1px solid  #000;
    --input-radio-inner-size: 6px;
    --input-radio-inner-position: 3px;
    // Toggler
    --input-toggler-toggled-color: #100AED;
    --input-toggler-untoggled-color: #{$davy-gray};
    --input-toggler-undoggled-circle-color: #FFFFFF;
    --input-toggler-width: 48px;
    --input-toggler-height: 24px;
    --input-toggler-radius: 12px;
    --input-toggler-position: 3px;
    // Header
    --header-primary-text-color: #161616;
    --header-secondary-text-color: #5A5A5A;
    --header-background-color: #FFFFFF;
    --header-highlight-color: #E7E7FD;
    // Dropdown
    --dropdown-background: #FBFAF9;
    --dropdown-primary-text-color: #161616;
    --dropdown-secondary-text-color: #5A5A5A;
    --dropdown-selected-color: #100AED;
    --dropdown-border: #5A5A5A;
    --dropdown-border-selected: #FBFAF9;
    --dropdown-option-hover: #F1F1F1;
    --dropdown-option-selected: #E7E7FD;
    --dropdown-option-invalid: #FCA833;
    --dropdown-option-warning-hover: #FEEACD;
    --dropdown-option-text-color: #161616;
    --dropdown-option-text-color-disabled: #8D8D8D;
    --dropdown-option-text-color-warning: #161616;
    --dropdown-option-border-hover: #F1F1F1;
    --dropdown-option-border-selected-hover: #100AED;
    --dropdown-option-border-warning-hover: #FEEACD;
    --dropdown-divider: #100AED;
    --dropdown-splitter: #F0ECE6;
    --dropdown-option-border-radius: 4px;
    --dropdown-footer: #FBFAF9;

    --dropdown-printmode-font-size: 12px;
    --dropdown-printmode-font-weight: 600;
    --dropdown-printmode-selected: #F1F1F1;
    --dropdown-printmode-selected-outline: transparent;
    --dropdown-printmode-hover: #E7E7FD;
    --dropdown-printmode-text: #161616;
    --dropdown-printmode-text-hover: #100AED;
    --dropdown-printmode-text-primary: #100AED;
    --dropdown-printmode-text-disabled: #706CF4;
    --dropdown-printmode-text-transform: none;

    --scene-tree-parent-selected: #CFCEFB;
    --scene-tree-item-selected: #E7E7FD;
    --scene-tree-item-icon-color: #100AED;
    --scene-tree-item-checkbox: #161616;
    --scene-tree-dropdown-hover: #F1F1F1;

    --theme-input-bg: #F1F1F1;
    --theme-input-border-color: transparent;
    --theme-input-border-radius: 4px;
    --theme-button-hover-background: #E7E7FD;
    --theme-button-hover-fill: #100AED;
    --theme-component-background-white: #FBFAF9;
    --theme-button-font-size: 14px;
    --theme-toaster-accent: #100AED;
    --theme-switch-inactive-border: #C6C6C6;

    --theme-sidebar-shadow: none !important;

    // Have to override the default font-family and font-weight from the makerbot-ui library
    span.accounts.alt, p, span, .text, .box-text, html, body, .i-status > span, table th, table td, .accounts-box .alert {
        font-family: 'Messina Sans', sans-serif;
        font-weight: 500;
    }
};
