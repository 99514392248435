.spinnerWrap {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinnerBorder {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: .25em solid #555;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinnerBorder .75s linear infinite;
  animation: spinnerBorder .75s linear infinite;

}

.textDark {
  color: #343a40 !important;
}

@keyframes spinnerBorder {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}