:global {
    @import '@makerbot/ui-library/scss/styles.scss';
}

@import '@makerbot/ui-library/scss/settings/_settings.scss';
@import '@ultimaker/stardust-web/dist/scss/_font-messina.scss';
@import '@ultimaker/stardust-web/dist/scss/_font-ibm-plex-mono.scss';
@import "./consts/styles.scss";
@import "./consts/variables.scss";

// Overrides the animation for all status-ring icons
:global(.status-ring) {
    :global(circle) {
        &:global(.print-fill) {
            animation: fillup 3s linear forwards;
        }

        &:global(.pause) {
            animation: dash 10s linear infinite;
        }

        &:global(.clear),
        &:global(.error) {
            animation: blink 1.5s linear infinite;
        }

        &:global(.prepping) {
            animation: dash 10s linear infinite;
        }
    }

    :global(.circle-icon) {
        :global(.prepping) {
            animation: spin 10s infinite linear !important;
        }
    }
}

:global(.prevent-select) {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.app {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
}

body,
html {
    min-height: 100vh;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
}

* {
    font-family: var(--theme-font-family);
    font-weight: var(--theme-font-weight);
}

p,
span {
    opacity: 1;
}
